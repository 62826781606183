<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：用户查询权限组件
	开始时间：2021-09-06
	开发人员：刘巍骏
	最后修改：2021-09-06
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="userPowerBox">
    <!-- 提示信息 -->
    <div class="userPowerLeft">
      <span class="title">制单人:</span>
    </div>
    <!-- 数据选择 -->
    <div class="userPowerRight">
      <!-- 需要分部门的(全公司,指定部门) -->
      <div v-if="userPowerType!=0">
        <el-cascader :options="departmentPowerData" v-model="personnel" :props="defaultProps" size="mini"
          :show-all-levels="false" @change="getPersonnel" collapse-tags clearable></el-cascader>
      </div>
      <!-- 不需要分部门的(本部门) -->
      <div v-if="userPowerType==0">
        <el-select class="selectInput" v-model="personnel" size="mini" placeholder="请选择制单人" multiple collapse-tags
          @change="getSelUserId">
          <el-option v-for="elem in departmentPowerData[0].userList" :key="elem.user_id" :label="elem.user_name"
            :value="elem.user_id" @click.native="judgeSelUserId(elem)">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  export default {
    name: 'userPowerSelect',
    props: {
      //用户权限类型(0:本部门 1:全公司 2:指定部门  3:仅个人)
      userPowerType: {
        type: Number,
        default: 1
      },
      // 有权限查询的部门id数组
      departmentPowerData: {
        type: Array,
        default () {
          return [];
        }
      },
    },
    data() {
      return {
        keyVal: 0, //el-cascader组件设置key值 用于刷新组件
        personnel: [-1], //当前所选员工
        //默认匹配企业公司员工树形结构字段
        defaultProps: {
          multiple: true, //是否开启多选
          expandTrigger: 'hover', //鼠标移入事件
          // checkStrictly: true,
          children: 'userList',
          value: 'user_id'
        },
      }
    },
    computed: {
      ...mapState({
        departmentData: state => state.hr.departmentData, //部门数据
        personnelData: state => state.hr.personnelData, //当前企业所有员工
      }),
    },
    watch: {
      // departmentPowerData() {
      //   this.departmentPowerData[0].userList.forEach(item => {
      //     this.personnel.push([undefined, item.user_id]) //默认勾选
      //   })
      // }
    },
    created() {},
    methods: {
      /* 获取当前级联选择器选择的数据 */
      getPersonnel(data) {
        if (data.length != 0 && data[0][0] == -1) {
          this.$emit("getSelectData", -1);
        } else {
          let personnelArr = [];
          data.forEach((item, index) => {
            personnelArr.push(item[1]) //将选中的员工id存入数组
          })
          // console.log(personnelArr);
          this.$emit("getSelectData", personnelArr);
        }
        return
        //判断查询条件
        if (data.length != 0 && data[0][0] == -1) { //全部查询
          this.personnel = [
            ['-1']
          ];
          this.$emit("getSelectData", -1);
        } else { //其他部门查询
          let allIndexOf = -1;
          let personnelArr = [];
          data.forEach((item, index) => {
            if (item[0] == undefined) {
              allIndexOf = index;
            }
            personnelArr.push(item[1]) //将选中的员工id存入数组
          })
          if (allIndexOf != -1) {
            this.personnel.splice(allIndexOf, 1);
          }
          this.$emit("getSelectData", personnelArr);
        }
      },

      /* 获取选择的用户id */
      getSelUserId(data) {
        if (data.user_id == -1) { //查询全部
          this.$emit("getSelectData", -1);
        } else {
          this.$emit("getSelectData", data);
        }
      },

      /* 判断当前选择的用户id */
      judgeSelUserId(data) {
        if (data.user_id == -1) {
          this.personnel = [-1];
        } else {
          let allIndexOf = this.personnel.indexOf(-1);
          if (allIndexOf != -1) {
            this.personnel.splice(allIndexOf, 1);
          }
        }
      },
    }
  }
</script>

<style lang="less" scoped="scoped">
  // 总框
  .userPowerBox{
    display: flex;
    align-items: center;
    // border: 1px solid black;
    //左边提示框
    .userPowerLeft{
      padding: 0 10px 0 0;
      // 标题提示信息
      .title:hover{
        // color: black;
      }
    }
    //右边选择内容
    .userPowerRight{
      // width: 160px;
    }
  }
</style>
