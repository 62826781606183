<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：单据导出公共弹框(优化版)
	开始时间：2021-12-16
	开发人员：刘巍骏
	最后修改：2021-12-16
	备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <el-dropdown trigger="click" placement="bottom" @command="exportBills">
      <span class="el-dropdown-link">
        打印导出<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <!-- 特殊打印方法 -->
        <!-- 生产计划单列表显示 -->
        <el-dropdown-item command="3" v-if="billsTypeSign == 1">生产单打印</el-dropdown-item>
        <el-dropdown-item command="4" v-if="billsTypeSign == 1">生产单打印(高级)</el-dropdown-item>
        <!-- 公共打印方法 -->
        <el-dropdown-item command="0" v-if="billsTypeSign == 1">计划单打印</el-dropdown-item>
        <el-dropdown-item command="5" v-if="billsTypeSign == 1">计划单打印(高级)</el-dropdown-item>
        <el-dropdown-item command="0" v-else>直接打印</el-dropdown-item>

        <!-- 高级打印方法 -->
        <el-dropdown-item command="1" v-if="billsTypeSign != 1">高级打印</el-dropdown-item>
        <el-dropdown-item command="2">导出例表</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- 高级打印弹框 -->
    <el-dialog :title="defaultTypeName+' 打印设置'" :visible.sync="isShowAdvancedBox" width="420px"
      :before-close="closeExportBox" center append-to-body id="billExportBox">
      <!-- 单据导出信息弹框 -->
      <div class="billing_dialog_form">
        <el-row class="headSelBillsType">
          <el-col :span="10" class="title">
            <span>请选择单据类型:</span>
          </el-col>
          <el-col :span="13" class="content">
            <el-select v-model="billsTypeId" placeholder="请选择单据" size="mini" @change="getChangeBillsTypeData()"
              style="width: 100%;">
              <el-option v-for="(item,index) in billsModelData" :key="item.id" :label="item.bills_type" :value="item.id"
                v-show="item.type_classify == billsClassify"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="billing_table" style="width: 100%;overflow: auto;height: 129px;">
          <el-col :span="24">
            <el-table :data="printTemplateData" height="129px" border style="width: 100%">
              <!-- 单选 -->
              <el-table-column width="35">
                <template slot-scope="scope">
                  　　<el-radio v-model="templateSeltion" :label="scope.$index" style="margin-left: 10px;"
                    @change.native="getCurrentRow(scope.$index,scope.row)">{{&nbsp;}}</el-radio>
                </template>
              </el-table-column>
              <!-- 数据内容 -->
              <el-table-column prop="name" label="模板名称" width="200" show-overflow-tooltip></el-table-column>
              <!-- 操作 -->
              <el-table-column label="模板预览" min-width="114">
                <template slot-scope="scope">
                  <i class="el-icon-view operate_icon" title="预览" @click="showFilePreView(scope.row)"></i>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row class="isUseHistory">
          <el-col :span="18" class="historyBillsModel" v-if="!!historyBillsModel">{{historyBillsModel}}</el-col>
          <el-col :span="18" class="historyBillsModel isClick" v-else><span @click="downloadOrPrint(4)">立即生成单据</span>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-checkbox v-model="isNew">更新</el-checkbox>
          </el-col>
        </el-row>
      </div>
      <!-- 提交按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="downloadOrPrint(1)">下载文件</el-button>
        <el-button size="small" type="danger" @click="downloadOrPrint(2)">立即打印</el-button>
        <el-button size="small" type="primary" @click="downloadOrPrint(3)">发送邮件</el-button>
        <div class="tip">
          如果更新了原单据数据或更换了打印模板，执行“<span class="fontRed">下载、打印、发邮件</span>”时，必须勾选“<span
            class="fontRed fontWeight">更新</span>”按钮，否则，不会生效，切记！
        </div>
      </span>
    </el-dialog>

    <!-- 导出列表弹框 -->
    <el-dialog title="导出列表" :visible.sync="isShowExportListBox" width="420px" :before-close="closeExportListBox"
      append-to-body id="billExportListBox">
      <div>
        <el-row class="exportListBox">
          <el-col :span="4" class="title">
            <span>导出:</span>
          </el-col>
          <el-col :span="13" class="content">
            <el-radio-group v-model="exportListType">
              <el-radio :label="0">导出所选</el-radio>
              <el-radio :label="1">导出全部</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row v-if="exportListType == 1">
          <el-col :span="24">
            <el-col :span="4">起止时间:</el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="startTime" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
            <el-col class="line" :span="3" :push="1"> - </el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="endTime" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeExportListBox">取 消</el-button>
        <el-button size="mini" type="primary" @click="commitExportList">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 条件打印选择弹框(生产计划单) -->
    <el-dialog title="条件选择" class="padding_top_0" :visible.sync="isShowConditionListBox" width="520px"
      :before-close="closeConditionListBox" id="conditionBox" append-to-body>
      <div class="conditionBox">
        <el-row class="conditon_title">
          <el-col :span="24" class="title">
            <span>筛选打印条件</span>
          </el-col>
        </el-row>
        <el-row class="conditon_item">
          <el-col :span="24">
            <el-col :span="5">订单时间:</el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="saleStartTime"
                @input="getSelectData($event,'saleStartTime')" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
            <el-col class="line" :span="3" :push="1"> - </el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="saleEndTime"
                @input="getSelectData($event,'saleEndTime')" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
          </el-col>
        </el-row>
        <el-row class="conditon_item">
          <el-col :span="24">
            <el-col :span="5">生产单时间:</el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="mesStartTime"
                @input="getSelectData($event,'mesStartTime')" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
            <el-col class="line" :span="3" :push="1"> - </el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="mesEndTime"
                @input="getSelectData($event,'mesEndTime')" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
          </el-col>
        </el-row>
        <el-row class="conditon_item">
          <el-col :span="24">
            <el-col :span="5">生产开始时间:</el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="mesStartTime1"
                @input="getSelectData($event,'mesStartTime1')" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
            <el-col class="line" :span="3" :push="1"> - </el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="mesEndTime1"
                @input="getSelectData($event,'mesEndTime1')" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
          </el-col>
        </el-row>
        <el-row class="conditon_item">
          <el-col :span="24">
            <el-col :span="5">交期时间:</el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="deliveryStartTime"
                @input="getSelectData($event,'deliveryStartTime')" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
            <el-col class="line" :span="3" :push="1"> - </el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="deliveryEndTime"
                @input="getSelectData($event,'deliveryEndTime')" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
          </el-col>
        </el-row>
        <el-row class="conditon_item">
          <el-col :span="24">
            <el-col :span="5">完工时间:</el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="completeStartTime"
                @input="getSelectData($event,'completeStartTime')" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
            <el-col class="line" :span="3" :push="1"> - </el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="completeEndTime"
                @input="getSelectData($event,'completeEndTime')" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
          </el-col>
        </el-row>
        <el-row class="conditon_item">
          <el-col :span="24">
            <el-col :span="5">指定客户:</el-col>
            <el-col :span="16">
              <el-input v-model="conditionCustomerName" @focus="getCustomer" @change="isClearCustomer" clearable
                size="mini" placeholder="请选择客户"></el-input>
            </el-col>
          </el-col>
        </el-row>
        <el-row class="conditon_item">
          <el-col :span="24">
            <el-col :span="5">指定产品:</el-col>
            <el-col :span="16">
              <el-input v-model="conditionProductName" @focus="getProductInfoBox" @change="isClearProduct" clearable
                size="mini" placeholder="请选择货品"></el-input>
            </el-col>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeConditionListBox">取 消</el-button>
        <el-button size="mini" type="primary" @click="commitConditionList">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 附件预览组件 -->
    <filePreview :previewFilePath="previewFilePath"></filePreview>

    <!-- 客户选择弹框 -->
    <customerList @SelectedData="getCustomerData" v-if="show_coustomerComponent">
    </customerList>

    <!-- 货品选择弹框 -->
    <productList @SelectedData="getProductData" :isMultiple="0" v-if="show_productComponent">
    </productList>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from '@/network/system/individuation'; //单据模板
  import sellApi from '@/network/sell/bills.js'; //销售模块
  import stockApi from '@/network/stock/stock.js'; //库存模块
  import costApi from '@/network/cost/account.js'; //资金模块
  import purchaseApi from '@/network/stock/purchase.js'; //采购模块
  import productApi from '@/network/production/production.js'; //生产模块
  import crmApi from '@/network/crm/crm.js'; //客户相关接口
  import workflowApi from '@/network/system/workflowSetup.js'; //流程相关接口

  //导入组件
  import filePreview from '@/components/commComponent/dialog/file_preview.vue'; //附件预览组件
  import customerList from '@/components/commComponent/list/list_customer'; //客户列表组件
  import productList from '@/components/commComponent/list/list_product'; //货品列表组件

  export default {
    name: 'bill_export',
    props: {
      //节点分类标志(0:销售 1:生产 2:库存  3:采购  4:bom  5.收付款  6:资金 7:工序单 8:发票)
      billsTypeSign: {
        type: Number,
        default: -1
      },
      // 单据模块子分类(根据billsTypeSign进一步区分)
      billsClassify: {
        type: Number,
        default: -1
      },
      //当前模块单据类型数据
      billsModelData: {
        type: Array | Object,
        default: []
      },
      //需要打印的源单数据(高级打印使用)
      originBillData: {
        type: Array | Object,
        default: []
      },
    },
    data() {
      return {
        // 高级打印弹框相关
        defaultTypeName: '', //进入时的默认单据类型名称
        billsTypeId: '', //当前选择的单据类型id
        billsTypeName: '', //当前选择的单据类型名称
        printTemplateData: [], //当前单据类型设置的打印模板数组
        templateSeltion: 0, //当前选中的打印模板数据
        billsModelId: -1, //当前选中的模板id
        excelName: '', //历史excle文件全称
        pdfName: '', //历史pdf文件全称
        historyBillsModel: '', //历史已生成的单据模板文件名称
        operate: "", //当前操作类型
        customerId: -1, //高级打印选择的单据客户id(发送邮件相关)

        //导出列表相关
        exportListType: 0, //导出方式(0:已选  1:全部)
        startTime: '', //开始时间
        endTime: '', //结束时间

        //条件打印相关
        saleStartTime: '', //订单开始时间
        saleEndTime: '', //订单结束时间
        mesStartTime: '', //计划单开始时间(计划单建单时间)
        mesEndTime: '', //计划单结束时间(计划单建单时间)
        mesStartTime1: '', //计划单开始时间(计划单开工时间)
        mesEndTime1: '', //计划单结束时间(计划单开工时间)
        deliveryStartTime: '', //交期开始时间
        deliveryEndTime: '', //交期结束时间
        completeStartTime: '', //完工开始时间
        completeEndTime: '', //完工结束时间
        conditionCustomerId: '', //客户id
        conditionCustomerName: '', //客户名称
        conditionProductId: '', //货品id
        conditionProductName: '', //货品名称

        //控制开关
        isShowAdvancedBox: false, //控制显示高级打印弹框
        isShowExportListBox: false, //控制显示导出列表弹框
        isShowConditionListBox: false, //控制显示条件打印弹框(生产计划单)
        isNew: false, //是否更新文件
        show_coustomerComponent: false, //控制加载客户组件
        show_productComponent: false, //控制加载货品选择组件

        //打印预览相关
        previewFilePath: '', //预览的文件名称及路径
      }
    },
    mounted() {

    },
    computed: {
      ...mapState({
        companyparamlist: state => state.companyparamlist, //获取企业级参数
        sellBillsKeys: state => state.system.sellBillsKeys, //销售单据导出字段
        putpayBillsKeys: state => state.system.putpayBillsKeys, //收付款单据导出字段
        expenseBillsKeys: state => state.system.expenseBillsKeys, //报销单据导出字段
        invoiceBillsKeys: state => state.system.invoiceBillsKeys, //发票单据导出字段
        costCheckKeys: state => state.system.costCheckKeys, //对账单据导出字段
        costSaleCheckKeys:state => state.system.costSaleCheckKeys, //销售对账单据导出字段
        costPurchaseCheckKeys:state => state.system.costPurchaseCheckKeys, //销售对账单据导出字段
        purchaseBillsKeys: state => state.system.purchaseBillsKeys, //报销单据导出字段
        warehoseBillsKeys: state => state.system.warehoseBillsKeys, //库存单据导出字段
        depotOutBillsKeys: state => state.system.depotOutBillsKeys, //出库单据导出字段
        depotInBillsKeys: state => state.system.depotInBillsKeys, //入库单据导出字段
        putpayOutBillsKeys: state => state.system.putpayOutBillsKeys, //付款单据导出字段
        putpayInBillsKeys: state => state.system.putpayInBillsKeys, //收款单据导出字段
        productBillsKeys: state => state.system.productBillsKeys, //生产计划单据导出字段
        productProcessKeys: state => state.system.productProcessKeys, //生产执行单据导出字段
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      })
    },
    methods: {
      ...mapMutations([
        'SHOW_FILEPREVIEW', //控制显示附件预览弹框
        'SHOW_CUSTOMERBOX', //控制客户选择弹框是否显示
        'SHOW_PRODUCTBOX', //控制产品选择弹框是否显示
      ]),

      /* 选择执行哪种打印方式 */
      exportBills(command) {
        //判断操作类型
        if (command == 0) { //直接打印
          //判断打印权限
          if (!this.judgeUserPrintOrExportPower(0)) {
            return;
          }

          //判断打印是否需要审核
          if (!this.judgePrintNeedCheck()) {
            return;
          }

          //判断不同模块是否有选择数据
          if (this.billsTypeSign == 7) { //生产执行单
            if (this.originBillData.length == 0) {
              this.$message({
                type: 'warning',
                message: '请选择需要打印的单据!',
                duration: this.elDuration
              })
              return
            }
          } else { //其他模块
            if (this.originBillData.length != 1) {
              this.$message({
                type: 'warning',
                message: '每次有且只能打印一张单据!',
                duration: this.elDuration
              })
              return
            }
          }

          //调用直接打印处理业务方法
          this.directPrint();
        } else if (command == 1) { //高级打印
          //判断打印权限
          if (!this.judgeUserPrintOrExportPower(0)) {
            return;
          }
          //判断是否有选择数据
          if (this.originBillData.length != 1) {
            this.$message({
              type: 'warning',
              message: '每次有且只能打印一张单据!',
              duration: this.elDuration
            })
            return
          }

          // 获取客户id(目前销售模块才有)
          if (this.billsTypeSign == 0) {
            this.customerId = this.originBillData[0].saleOrderMain.customerId;
          }

          //获取默认单据类型
          this.billsTypeId = this.originBillData[0].billingTypeInfo.id; //单据类型id
          this.billsTypeName = this.originBillData[0].billingTypeInfo.bills_type; //单据类型名称
          this.defaultTypeName = this.originBillData[0].billingTypeInfo.bills_type; //默认单据类型名称
          //获取对应的单据模板列表
          this.getTemplatePrintData(this.billsTypeId);

          //显示高级打印弹框
          this.isShowAdvancedBox = true;
        } else if (command == 2) { //导出列表
          //判断导出权限
          if (!this.judgeUserPrintOrExportPower(1)) {
            return;
          }
          //获取默认开始日期和结束日期
          let today = new Date();
          //设置时间为零点
          today.setHours(0, 0, 0, 0);
          //结束时间默认当前日期
          this.endTime = today;
          //开始时间默认当前日期往前推90天
          this.startTime = new Date(today.getTime() - 24 * 60 * 60 * 1000 * 90);
          //显示导出列表弹框
          this.isShowExportListBox = true;
        } else if (command == 3) { //按条件打印(生产计划单)
          //判断打印权限
          if (!this.judgeUserPrintOrExportPower(0)) {
            return;
          }

          //判断是否手动勾选单据
          if (this.originBillData.length > 0) {
            //生产单据id
            let mesIdArr = [];

            //循环获取id
            this.originBillData.forEach((item, index) => {
              mesIdArr.push(item.id);
            })

            //打开打印界面
            this.openMesConditonPrintPage(mesIdArr.join(","));
            return
          }

          //初始化表单数据
          this.initPrintConditionData();

          //显示条件打印选择框
          this.isShowConditionListBox = true;
        } else if (command == 4) { //生产单打印(高级)
          //判断打印权限
          if (!this.judgeUserPrintOrExportPower(0)) {
            return;
          }

          //生产单据id
          let mesIdArr = [];

          //循环获取id
          this.originBillData.forEach((item, index) => {
            mesIdArr.push(item.id);
          })

          // 判断是否选择单据
          if(mesIdArr.length == 0){
            this.$message({
              type:'warning',
              message:'请选择需要打印的单据信息!',
              duration:this.elDuration
            })
            return
          }

          //获取默认单据类型(生产通知单)
          let mesInform = this.billsModelData.filter(item => item.code == 'DOC-M-017');
          if(mesInform.length == 0){
            this.$message({
              type:'warning',
              message:'企业未配置【生产通知单】单据类型,请联系管理员!',
              duration:this.elDuration
            })
            return
          }

          this.billsTypeId = mesInform[0].id; //单据类型id
          this.billsTypeName = mesInform[0].bills_type; //单据类型名称
          this.defaultTypeName = mesInform[0].bills_type; //默认单据类型名称
          //获取对应的单据模板列表
          this.getTemplatePrintData(this.billsTypeId);

          //显示高级打印弹框
          this.isShowAdvancedBox = true;
        } else if (command == 5) { //计划单打印(高级)
          //判断打印权限
          if (!this.judgeUserPrintOrExportPower(0)) {
            return;
          }

          //判断是否有选择数据
          if (this.originBillData.length != 1) {
            this.$message({
              type: 'warning',
              message: '每次有且只能打印一张单据!',
              duration: this.elDuration
            })
            return
          }

          //获取默认单据类型
          this.billsTypeId = this.originBillData[0].billingTypeInfo.id; //单据类型id
          this.billsTypeName = this.originBillData[0].billingTypeInfo.bills_type; //单据类型名称
          this.defaultTypeName = this.originBillData[0].billingTypeInfo.bills_type; //默认单据类型名称
          //获取对应的单据模板列表
          this.getTemplatePrintData(this.billsTypeId);

          //显示高级打印弹框
          this.isShowAdvancedBox = true;
        }
      },

      /* 直接打印进行的操作 */
      directPrint() {
        //设置单据模块
        setCookie('billsTypeSign', this.billsTypeSign);
        //设置是否显示旧物料编码
        setCookie('showOldProductCode', this.companyparamlist.param345 == "0" ? true : false);
        //领料单位按bom单位显示(领料单用)
        setCookie('materialCalculateIsBom', this.companyparamlist.param367 == "0" ? true : false);
        //设置打印模板id
        setCookie('printMouldId', this.originBillData[0].billingTypeInfo.print_template_id);
        // 判断不同模块
        if (this.billsTypeSign == 0) { //销售
          //设置请求id
          setCookie('billsId', this.originBillData[0].saleOrderMain.id);
          setCookie('orderType', this.billsClassify);
          setCookie('statusId', this.originBillData[0].saleOrderMain.statusId);
        } else if (this.billsTypeSign == 1) { //生产计划
          setCookie('billsId', this.originBillData[0].id);
          setCookie('statusId', this.originBillData[0].statusId);
          setCookie('mesThName', 0) //生产计划单
        } else if (this.billsTypeSign == 2) { //出入库
          setCookie('billsId', this.originBillData[0].depotMain.id);
          setCookie('statusId', this.originBillData[0].depotMain.statusId);
          setCookie('isSendBill', -1); //1时为送货单,不传为其他库存相关单据
          //判断特殊单据类型
          if (this.originBillData[0].billingTypeInfo.code == 'DOC-M-014') { //生产领料出库单
            setCookie('billsTypeSign', 1); //设置为生产模块
            // 判断源单类型
            if (this.originBillData[0].depotMain.sign == 1) { //生产计划单
              setCookie('mesThName', 2); //领料单 多工序
            } else if (this.originBillData[0].depotMain.sign == 7) { //生产执行单
              setCookie('mesThName', 2); //领料单 多工序
            }
          }
          if (this.originBillData[0].billingTypeInfo.code == 'DOC-M-011') { //生产退料入库单
            setCookie('billsTypeSign', 1); //设置为生产模块
            // 判断源单类型
            if (this.originBillData[0].depotMain.sign == 1) { //生产计划单
              setCookie('mesThName', 7); //退料单 多工序
            } else if (this.originBillData[0].depotMain.sign == 7) { //生产执行单
              setCookie('mesThName', 7); //退料单 多工序
            }
          }
        } else if (this.billsTypeSign == 3) { //采购
          setCookie('billsId', this.originBillData[0].purchaseOrderMain.id);
          setCookie('statusId', this.originBillData[0].purchaseOrderMain.statusId);
        } else if (this.billsTypeSign == 5) { //收付款
          setCookie('billsId', this.originBillData[0].costBillsMain.id);
          setCookie('statusId', this.originBillData[0].costBillsMain.statusId);
        } else if (this.billsTypeSign == 6) { //报销
          setCookie('billsId', this.originBillData[0].costBillsExpense.id);
          setCookie('statusId', this.originBillData[0].costBillsExpense.statusId);
        } else if (this.billsTypeSign == 7) { //生产执行
          //获取id字符串
          let billsIdArr = [];
          this.originBillData.forEach((item, index) => {
            billsIdArr.push(item.id);
          })
          setCookie('billsId', billsIdArr.join(",")); //单据id
        } else if (this.billsTypeSign == 8) { //发票
          setCookie('billsId', this.originBillData[0].costBillsInvoice.id);
          setCookie('statusId', this.originBillData[0].costBillsInvoice.statusId);
        } else if (this.billsTypeSign == 9) { //对账
          setCookie('billsId', this.originBillData[0].id);
          setCookie('statusId', this.originBillData[0].statusId);
          setCookie('costCheckClassify', this.originBillData[0].checkClassify);
        }
        //打开打印页面
        if (this.originBillData[0].billingTypeInfo.file_path) {
          // 判断模块
          if (this.billsTypeSign == 2) { //库存模块
            // //判断特殊单据类型
            // if(this.originBillData[0].billingTypeInfo.code == 'DOC-M-014'){//生产领料出库单

            // }else{
            //   window.open(baseUrl + this.originBillData[0].billingTypeInfo.file_path);
            // }
            window.open(baseUrl + this.originBillData[0].billingTypeInfo.file_path);
          } else if (this.billsTypeSign == 7) { //生产执行
            setCookie('billsTypeSign', 1); //设置标识为生产
            // 判断是单工序还是多工序
            if (this.originBillData.length == 1) { //单工序
              setCookie('mesThName', 3) //单工序
              window.open(baseUrl + '/upload/orderPrint/PrintModel/html/mes/mes_single_implement_model.html'); //打开打印页面
            } else if (this.originBillData.length > 1) { //多工序
              setCookie('mesThName', 4) //多工序
              window.open(baseUrl +
                '/upload/orderPrint/PrintModel/html/mes/mes_multiple_implement_model.html'); //打开打印页面
            }
          } else { //其他模块
            window.open(baseUrl + this.originBillData[0].billingTypeInfo.file_path);
          }
        } else {
          this.$message({
            type: 'warning',
            message: '系统未配置打印文件,请联系管理员!',
            duration: this.elDuration
          })
        }
      },

      /* 判断用户打印或导出权限(type(0:打印  1:导出)) */
      judgeUserPrintOrExportPower(type) {
        let powerArr = [];
        // 根据不同模块判断权限
        if (this.billsTypeSign == 0) { //销售
          //根据子模块判断当前用户是否有打印权限
          if (this.billsClassify == 0) { //报价单
            powerArr = [14, 1];
          } else if (this.billsClassify == 1) { //订单
            powerArr = [14, 2];
          }
        } else if (this.billsTypeSign == 1) { //生产
          powerArr = [16, 1];
        } else if (this.billsTypeSign == 2) { //库存
          if (this.billsClassify == 0) { //入库单
            powerArr = [15, 1];
          } else if (this.billsClassify == 1) { //出库单
            powerArr = [15, 2];
          } else if (this.billsClassify == 5) { //调拨单
            powerArr = [15, 3];
          } else if (this.billsClassify == 2) { //报损单
            powerArr = [15, 4];
          } else if (this.billsClassify == 3) { //组装单
            powerArr = [15, 5];
          } else if (this.billsClassify == 4) { //拆卸单
            powerArr = [15, 6];
          } else if (this.billsClassify == 9) { //退货单(需调整)
            powerArr = [15, 1];
          } else if (this.billsClassify == 10) { //质检单
            powerArr = [15, 10];
          }
        } else if (this.billsTypeSign == 3) { //采购
          //根据子模块判断当前用户是否有打印权限
          if (this.billsClassify == 0) { //采购申请
            powerArr = [23, 1];
          } else if (this.billsClassify == 1) { //采购订单
            powerArr = [23, 2];
          }
        } else if (this.billsTypeSign == 5) { //收付款
          //根据子模块判断当前用户是否有打印权限
          if (this.billsClassify == 0) { //收款单
            powerArr = [17, 2];
          } else if (this.billsClassify == 1) { //付款单
            powerArr = [17, 1];
          }
        } else if (this.billsTypeSign == 6) { //报销
          //获取权限数据
          powerArr = [17, 3];
        } else if (this.billsTypeSign == 7) { //生产执行
          //获取权限数据
          powerArr = [16, 9];
        } else if (this.billsTypeSign == 8) { //发票
          //获取权限数据
          powerArr = [17, 5];
        } else if (this.billsTypeSign == 9) { //对账
          console.log('对账导出权限未加');
          return true;
        }
        // 判断是导出还是打印
        if (type == 0) { //打印
          //判断权限
          if (this.commonJsExtend.isHaveThePower(powerArr[0], powerArr[1], 8, '打印')) {
            return false;
          } else {
            return true;
          }
        } else if (type == 1) { //导出
          //判断是否有导出权限
          if (this.commonJsExtend.isHaveThePower(powerArr[0], powerArr[1], 5, '导出')) {
            return false;
          } else {
            return true;
          }
        }
      },

      /* 判断打印单据是否需要审核 */
      judgePrintNeedCheck() {
        let num = 0;
        if (this.billsTypeSign == 0) { //销售
          //判断单据是否审核后才能打印
          if (this.companyparamlist.param280 == 0) {
            if (this.originBillData[0].saleOrderMain.isSubmit < 4) {
              num = 1;
            }
          }
        } else if (this.billsTypeSign == 1) { //生产计划
          //判断单据是否审核后才能打印
          if (this.companyparamlist.param266 == 0) {
            console.log(this.originBillData[0].is_submit)
            if (this.originBillData[0].is_submit < 4) {
              num = 1;
            }
          }
        } else if (this.billsTypeSign == 2) { //库存
          //判断单据是否审核后才能打印
          if (this.companyparamlist.param328 == 0) {
            if (this.originBillData[0].depotMain.isSubmit < 4) {
              num = 1;
            }
          }
        } else if (this.billsTypeSign == 3) { //采购
          //判断单据是否审核后才能打印
          if (this.companyparamlist.param299 == 0) {
            if (this.originBillData[0].purchaseOrderMain.isSubmit < 4) {
              num = 1;
            }
          }
        } else if (this.billsTypeSign == 5) { //收付款
          //判断单据是否审核后才能打印
          console.log("未设置审核打印权限");
        } else if (this.billsTypeSign == 6) { //报销
          //判断单据是否审核后才能打印
          console.log("未设置审核打印权限");
        } else if (this.billsTypeSign == 7) { //生产执行
          //判断单据是否审核后才能打印
          if (this.companyparamlist.param340 == 0) {
            if (this.originBillData[0].isSubmit < 4) {
              num = 1;
            }
          }
        } else if (this.billsTypeSign == 8) { //发票
          //判断单据是否审核后才能打印
          console.log("未设置审核打印权限");
        }
        //判断结果
        if (num == 0) {
          return true;
        } else if (num == 1) {
          this.$message({
            type: 'warning',
            message: '管理员已设置单据必须审核后才能打印!',
            duration: this.elDuration
          })
          return false;
        }
      },

      /* 判断导出单据是否需要审核 */
      judgeExportNeedCheck() {
        //定义接受不合理的数据数量
        let num = 0;
        if (this.billsTypeSign == 0) { //销售
          //判断单据是否审核后才能导出
          if (this.companyparamlist.param281 == 0) {
            this.originBillData.forEach((item, index) => {
              if (item.saleOrderMain.isSubmit < 4) {
                num++;
              }
            })
          }
        } else if (this.billsTypeSign == 1) { //生产计划
          //判断单据是否审核后才能导出
          if (this.companyparamlist.param274 == 0) {
             num = 0;
            this.originBillData.forEach((item, index) => {
              if (item.is_submit< 4) {
                num++;
              }
            })
          }
        } else if (this.billsTypeSign == 2) { //库存
          //判断单据是否审核后才能导出
          if (this.companyparamlist.param329 == 0) {
             num = 0;
            this.originBillData.forEach((item, index) => {

              if (item.depotMain.isSubmit < 4) {
                num++;
              }
            })
          }
        } else if (this.billsTypeSign == 3) { //采购
          //判断单据是否审核后才能导出
          if (this.companyparamlist.param300 == 0) {
             num = 0;
            this.originBillData.forEach((item, index) => {
              if (item.purchaseOrderMain.isSubmit < 4) {
                num++;
              }
            })
          }
        } else if (this.billsTypeSign == 5) { //收付款
          //判断单据是否审核后才能导出
          console.log("未设置审核导出权限");
        } else if (this.billsTypeSign == 6) { //报销
          //判断单据是否审核后才能导出
          console.log("未设置审核导出权限");
        } else if (this.billsTypeSign == 7) { //生产执行
          //判断单据是否审核后才能导出
          if (this.companyparamlist.param341 == 0) {
             num = 0;
            this.originBillData.forEach((item, index) => {
              if (item.isSubmit < 4) {
                num++;
              }
            })
          }
        } else if (this.billsTypeSign == 8) { //发票
          //判断单据是否审核后才能导出
          console.log("未设置审核导出权限");
        }
        //判断是否有未审核数据

        if (num > 0) {
          this.$message({
            type: 'warning',
            message: '管理员已设置单据必须审核后才能导出,已选数据存在未审核数据!',
            duration: this.elDuration
          })
          return false;
        } else {
          return true;
        }
      },

      /* 获取单据选择的类型数据 */
      getChangeBillsTypeData() {
        //获取单据类型名称
        let curBillTypeArr = this.billsModelData.filter(item => item.id == this.billsTypeId);
        if (curBillTypeArr.length > 0) {
          this.billsTypeName = curBillTypeArr[0].bills_type;
        }
        //获取单据模板数据
        this.getTemplatePrintData(this.billsTypeId);
      },

      /* 根据单据类型获取单据模板数据 */
      getTemplatePrintData(billsTypeId) {
        //定义后端接受参数
        let data = {
          templateId: billsTypeId
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#billExportBox", 4, '数据请求中,请稍候...');
        //发送请求
        api.findByElectronicTemplateId(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取当前单据类型已上传单据模板数据
            this.printTemplateData = res.data;
            //获取当前单据类型设置的默认模板
            if (this.printTemplateData.length > 0) {
              //获取单据模板配置中选中的模板
              let template = this.billsModelData.filter(item => item.id == billsTypeId);

              //选中的模板id
              let templateId = '';
              if (template) {
                templateId = template[0].electronic_template_id;
              }
              //匹配单据模板配置中选中的模板
              let defaultRow = this.printTemplateData.filter(item => item.id == templateId);
              //判断是否有设置模板
              if (defaultRow.length != 0) { //如果有选中模板
                //选中默认设置的模板
                this.templateSeltion = this.printTemplateData.indexOf(defaultRow[0]);
                console.log(this.billsModelId);
                //选中的默认模板的id
                this.billsModelId = defaultRow[0].id;
                // if (this.billsModelId == -1) {
                //   this.billsModelId = defaultRow[0].id;
                // }
                //查询单据已生成文件
                this.findOrderTaskByOrderId();
              } else { //如果没有选中模板
                this.templateSeltion = 0;
                this.billsModelId = this.printTemplateData[0].id; //默认第一个
              }
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 查询已经生成的模板文件 */
      findOrderTaskByOrderId() {
        //定义当前选中单据id
        let orderId = this.originBillData[0].id;
        //定义后端接受参数
        let data = {
          orderId, //单据id
          sign: this.billsTypeSign, //单据类型
          mouldId: this.billsModelId //单据模板id
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#billExportBox", 4, '数据请求中,请稍候...');
        //发送请求
        api.findOrderTaskByOrderId(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //判断有无生成过历史大怒
            if (res.data.length > 0) { //生成过
              //文件路径数组
              let filePathArr = [];
              //循环获取数据
              res.data.forEach(item => {
                if (item.file_path) {
                  //判断文件类型
                  if (item.file_type == 0) { //excel
                    //获取历史excle文件路径
                    this.historyExcel = item.file_path;
                    //历史生成单据名称
                    filePathArr = item.file_path.split('//');
                    //excle文件全称
                    this.excelName = decodeURI(filePathArr[filePathArr.length - 1]).split('.')
                    this.historyBillsModel = decodeURI(filePathArr[filePathArr.length - 1]).split('.')[0]
                  } else if (item.file_type == 1) { //pdf
                    //获取历史生成的pdf文件路径
                    this.historyPdf = item.file_path;
                    //pdf文件全称
                    this.pdfName = decodeURI(filePathArr[filePathArr.length - 1]).split('.')
                  }
                }
              })
            } else {
              this.historyBillsModel = '' //历史使用单据模板
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 下载打印 type 1:下载 2:打印 3:发送邮件 4:立即生成*/
      downloadOrPrint(type) {
        //获取当前操作类型
        this.operate = type;
        //判断是否需要重新生成文件
        if (this.isNew || this.historyBillsModel == '') {
          this.generateBillsFile();
        } else {
          // 判断执行方式
          if (type == 1) { //下载
            this.downloadFile(this.historyExcel, this.excelName);
          } else if (type == 2) { //打印
            this.downloadFile(this.historyPdf, this.pdfName);
          } else if (type == 3) { //发送邮件
            this.sendMailData();
          } else if (type == 4) { //立即生成
            this.generateBillsFile();
          }
        }
      },

      /* 生产单据打印及导出文件 */
      generateBillsFile() {
        //判断是否已选模板
        if (this.billsModelId == -1) {
          this.$message({
            type: 'warning',
            message: "请选择模板文件!",
            duration: this.elDuration
          })
          return
        }
        //定义后端接收参数
        let exportData = {
          type: this.operate == 2 ? 1 : 0, //格式(1:pdf格式,返回一个pdf文件路径)
          mould_id: this.billsModelId, //模板id
          name: this.billsTypeName, //单据类型名称
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#billExportBox", 4, '单据生成中,请稍候...');
        //根据不同模块发送不同请求
        if (this.billsTypeSign == 0) { //销售
          //获取单据id
          exportData.id = this.originBillData[0].saleOrderMain.id;
          //发送请求
          sellApi.generateOrderExport(exportData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //调用处理文件方法
              this.disposeAfterGenerateFile(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 1) { //生产计划
          //获取当前选择的单据类型
          let cutBillsType = this.billsModelData.filter(item=>item.id == this.billsTypeId);

          //判断生成通知单单独使用接口
          if(cutBillsType[0].code == "DOC-M-017"){
            //获取单据id
            let mesIdArr = [];
            this.originBillData.forEach((item,index)=>{
              mesIdArr.push(item.id);
            })
            exportData.id = mesIdArr.join(",");
            //发送请求
            productApi.exportmesMain(exportData).then(res => {
              this.loading.close();
              if (res.code == 200) {
                //调用处理文件方法
                this.disposeAfterGenerateFile(res.data);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }else{
            //获取单据id
            exportData.id = this.originBillData[0].id;
            //发送请求
            productApi.addMesMainExport(exportData).then(res => {
              this.loading.close();
              if (res.code == 200) {
                //调用处理文件方法
                this.disposeAfterGenerateFile(res.data);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        } else if (this.billsTypeSign == 2) { //库存
          //获取单据id
          exportData.id = this.originBillData[0].depotMain.id;
          //发送请求
          stockApi.addDepotMainExport(exportData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //调用处理文件方法
              this.disposeAfterGenerateFile(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 3) { //采购
          //获取单据id
          exportData.id = this.originBillData[0].purchaseOrderMain.id;
          //发送请求
          purchaseApi.addPurchaseMainExport(exportData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //调用处理文件方法
              this.disposeAfterGenerateFile(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 5) { //收付款
          //获取单据id
          exportData.id = this.originBillData[0].costBillsMain.id;
          //发送请求
          costApi.addCostBillsMainExport(exportData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //调用处理文件方法
              this.disposeAfterGenerateFile(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 6) { //报销
          //获取单据id
          exportData.id = this.originBillData[0].costBillsExpense.id;
          //发送请求
          costApi.addCostBillsExpenseExport(exportData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //调用处理文件方法
              this.disposeAfterGenerateFile(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 7) { //生产执行
          //获取单据id
          exportData.id = this.originBillData[0].id;
          //发送请求
          productApi.addMesProcessExport(exportData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //调用处理文件方法
              this.disposeAfterGenerateFile(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 8) { //发票
          //获取单据id
          exportData.id = this.originBillData[0].costBillsInvoice.id;
          //发送请求
          costApi.addCostBillsInvoiceExport(exportData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //调用处理文件方法
              this.disposeAfterGenerateFile(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.billsTypeSign == 9) { //对账单

          //获取单据id
          exportData.id = this.originBillData[0].id;
          //发送请求
          costApi.addCostBillsCheckExport(exportData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //调用处理文件方法
              this.disposeAfterGenerateFile(res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 获取当前选择的模板 */
      getCurrentRow(index, data) {
        //获取打印模板id
        this.billsModelId = data.id;
      },

      /* 处理文件生成后的操作 */
      disposeAfterGenerateFile(data) {
        //定义相关参数
        let filePathArr = []; //文件路径分割接受数组
        let filename = ''; //文件名称
        //判断有无生成文件
        if (data) {
          filePathArr = data.split('//');
          filename = decodeURI(filePathArr[filePathArr.length - 1]);
        }
        //判断操作类型
        if (this.operate == 1 || this.operate == 2) { //下载打印
          this.downloadFile(data, filename);
        } else if (type == 3) { //发送邮件
          this.sendMailData();
        } else if (type == 4) { //立即生成
          this.findOrderTaskByOrderId();
        }
      },

      /* 下载文件或打开pdf文件 */
      downloadFile(href, name) {
        if (this.operate == 2) { //为pdf文件时
          let filePreViewData = {
            file_path: href,
            name
          }
          this.showFilePreView(filePreViewData)
        } else if (this.operate == 1) { //为其他文件时
          const link = document.createElement("a");
          link.style.display = 'none';
          link.href = baseUrl + href;
          link.download = name;
          document.body.appendChild(link);
          link.click();
        }
      },

      /* 发送邮件功能 */
      sendMailData() {
        // //定义邮件发送需要的数据
        // let orderSendMailMsg = {
        //   address: "aaa", //收件人地址
        //   filsSize: 1024, //附件大小
        //   fileName: "Q202172241922.xls@espeed@Q202172241922.xls", //附件名称
        // }
        // //将数据存储到cookie
        // setCookie("orderSendMailMsg", JSON.stringify(orderSendMailMsg));
        // //定义路由参数
        // // let href = baseCrmWebUrl + "/mail?mailOperateType=4";
        // // window.open(href, "_blank");
        // return

        //判断有无生成文件
        if (this.historyPdf == '') {
          this.$message({
            type: 'warning',
            message: '请先生成文件!',
            duration: this.elDuration
          })
          return
        }

        //获取收件人信息
        if (this.customerId == -1) {
          this.$message({
            type: 'warning',
            message: '该单据无客户信息,请确认!',
            duration: this.elDuration
          })
          return
        } else {
          let crmData = {
            customerid: this.customerId,
          }
          this.loading = this.commonJsExtend.customLoading("#billExportBox", 4, '客户联系人信息获取中,请稍候...');
          //获取联系人信息
          crmApi.findLinkManByCustomerid(crmData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取联系人数据
              let linkManData = res.data;
              let mailAddress = "";
              //判断是否有联系人
              if (linkManData.length > 0) {
                //获取主联系人
                linkManData = linkManData.filter(item => item.isMainLinkman == 1);
                //判断是否有主联系人
                if (linkManData.length > 0) {
                  let linkManObj = linkManData[0];
                  //获取联系人邮箱地址
                  mailAddress = `${linkManObj.fullName}<${linkManObj.email}>`
                  //将文件生成到临时文件夹
                  let fileData = {
                    file_path: decodeURI(this.historyPdf)
                  }
                  this.loading = this.commonJsExtend.customLoading("#billExportBox", 4, '文件处理中,请稍候...');
                  // 发送请求
                  sellApi.copeFileToEmailTemp(fileData).then(res => {
                    this.loading.close();
                    if (res.code == 200) {
                      //定义邮件发送需要的数据
                      let orderSendMailMsg = {
                        address: mailAddress, //收件人地址
                        filsSize: res.data.fileSize, //附件大小
                        fileName: res.data.fileUrl, //附件名称
                      }
                      //将数据存储到cookie
                      setCookie("orderSendMailMsg", JSON.stringify(orderSendMailMsg));
                      //定义路由参数
                      let href = baseCrmWebUrl + "/mail?mailOperateType=4";
                      window.open(href, "_blank");
                    } else {
                      this.$message({
                        type: 'error',
                        message: res.message,
                        duration: this.elDuration
                      })
                    }
                  })
                } else {
                  this.$message({
                    type: 'warning',
                    message: "未查询到主联系人,请确认!",
                    duration: this.elDuration
                  })
                  return
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: "该客户暂未设置联系人,请确认!",
                  duration: this.elDuration
                })
                return
              }
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 提交导出列表 */
      commitExportList() {
        // 判断单据是否审核后才能导出
        if (!this.judgeExportNeedCheck()) {
          return
        }
        //定义导出的列表数据id字段
        let exportIdsArr = [];
        // 判断导出方式
        if (this.exportListType == 0) { //导出所选
        this.startTime=''
        this.endTime=''
          //判断是否已选数据
          if (this.originBillData.length == 0) {
            this.$message({
              type: 'warning',
              message: '请先选择需要导出的数据!',
              duration: this.elDuration,
            })
            return
          }
          //循环处理数据
          this.originBillData.forEach((item, index) => {
            //根据不同模块获取ids
            if (this.billsTypeSign == 0) { //销售
              exportIdsArr.push(item.saleOrderMain.id);
            } else if (this.billsTypeSign == 1) { //生产计划
              exportIdsArr.push(item.id);
            } else if (this.billsTypeSign == 2) { //库存
              exportIdsArr.push(item.depotMain.id);
            } else if (this.billsTypeSign == 3) { //采购
              exportIdsArr.push(item.purchaseOrderMain.id);
            } else if (this.billsTypeSign == 5) { //收付款
              exportIdsArr.push(item.costBillsMain.id);
            } else if (this.billsTypeSign == 6) { //报销
              exportIdsArr.push(item.costBillsExpense.id);
            } else if (this.billsTypeSign == 7) { //生产执行
              exportIdsArr.push(item.id);
            } else if (this.billsTypeSign == 8) { //发票
              exportIdsArr.push(item.costBillsInvoice.id);
            }
            else if(this.billsTypeSign==9){//对账
              exportIdsArr.push(item.id);
            }
          })
        } else if (this.exportListType == 1) { //导出全部
          //判断日期差
          if (!this.commonJsExtend.getDifferTime(this.startTime, this.endTime)) {
            return
          }
        }
        //定义后端公共接受参数
        let exportData = {
          type: 0, //格式
          ids: exportIdsArr.join(","),
          startTime: this.startTime,
          endTime: this.endTime,
        }
        console.log(exportData)
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#billExportListBox", 4, '单据列表导出中,请稍候...');
        //根据不同的模块发送不同的请求
        if (this.billsTypeSign == 0) { //销售
          exportData.fields = this.sellBillsKeys.join(','); //需要导出的字段名
          exportData.isSubmit = 0; //是否仅导出已审核数据(需判断权限)
          //发送请求
          sellApi.exportSaleOrderMainList(exportData).then(res => {
            if (res.code == 200 && res.data) {
              let filePathArr = [];
              let name = '';
              filePathArr = res.data.split('//')
              name = decodeURI(filePathArr[filePathArr.length - 1])
              //设置操作类型为导出excle
              this.operate = 1;
              //下载文件
              this.downloadFile(res.data, name, 1)
              //关闭导出弹框
              this.closeExportListBox()
              this.loading.close();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        } else if (this.billsTypeSign == 1) { //生产计划
          exportData.fields = this.productBillsKeys.join(','); //需要导出的字段名
          exportData.isSubmit = 0; //是否仅导出已审核数据
          //发送请求
          productApi.exportMesList(exportData).then(res => {
            if (res.code == 200 && res.data) {
              let filePathArr = [];
              let name = '';
              filePathArr = res.data.split('//')
              name = decodeURI(filePathArr[filePathArr.length - 1])
              //设置操作类型为导出excle
              this.operate = 1;
              //下载文件
              this.downloadFile(res.data, name, 1)
              //关闭导出弹框
              this.closeExportListBox()
              this.loading.close();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        } else if (this.billsTypeSign == 2) { //出入库
          if(this.billsClassify==0){
            exportData.fields = this.depotInBillsKeys.join(','); //需要导出的字段名
          }
          else if(this.billsClassify==1){//当为出库单的时候
            exportData.fields = this.depotOutBillsKeys.join(','); //需要导出的字段名
          }
          exportData.isSubmit = 0; //是否仅导出已审核数据(需判断权限)
          exportData.billsClassify = this.billsClassify;//出入库类型
          //发送请求
          stockApi.exportDepotMainList(exportData).then(res => {
            if (res.code == 200 && res.data) {
              let filePathArr = [];
              let name = '';
              filePathArr = res.data.split('//')
              name = decodeURI(filePathArr[filePathArr.length - 1])
              //设置操作类型为导出excle
              this.operate = 1;
              //下载文件
              this.downloadFile(res.data, name, 1)
              //关闭导出弹框
              this.closeExportListBox()
              this.loading.close();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
             this.loading.close();
            }
          })
        } else if (this.billsTypeSign == 3) { //采购
          exportData.fields = this.purchaseBillsKeys.join(','); //需要导出的字段名
          exportData.isSubmit = 0; //是否仅导出已审核数据(需判断权限)
          //发送请求
          purchaseApi.exportPurchaseList(exportData).then(res => {
            if (res.code == 200 && res.data) {
              let filePathArr = [];
              let name = '';
              filePathArr = res.data.split('//')
              name = decodeURI(filePathArr[filePathArr.length - 1])
              //设置操作类型为导出excle
              this.operate = 1;
              //下载文件
              this.downloadFile(res.data, name, 1)
              //关闭导出弹框
              this.closeExportListBox()
              this.loading.close();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        } else if (this.billsTypeSign == 5) { //收付款
          if(this.billsClassify==0){//当为收款单
          exportData.fields = this.putpayInBillsKeys.join(','); //需要导出的字段名
                    }
        else if(this.billsClassify==1){//当为付款单的时候
              exportData.fields = this.putpayOutBillsKeys.join(','); //需要导出的字段名
        }
          exportData.isSubmit = 0; //是否仅导出已审核数据(需判断权限)
          exportData.billsClassify=this.billsClassify//单据相关类型
          //发送请求
          costApi.exportCostBillsList(exportData).then(res => {
            if (res.code == 200 && res.data) {
              let filePathArr = [];
              let name = '';
              filePathArr = res.data.split('//')
              name = decodeURI(filePathArr[filePathArr.length - 1])
              //设置操作类型为导出excle
              this.operate = 1;
              //下载文件
              this.downloadFile(res.data, name, 1)
              //关闭导出弹框
              this.closeExportListBox()
              this.loading.close();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        } else if (this.billsTypeSign == 6) { //报销
          exportData.fields = this.expenseBillsKeys.join(','); //需要导出的字段名
          exportData.isSubmit = 0; //是否仅导出已审核数据(需判断权限)
          //发送请求
          costApi.exportCostExpenseList(exportData).then(res => {
            if (res.code == 200 && res.data) {
              let filePathArr = [];
              let name = '';
              filePathArr = res.data.split('//')
              name = decodeURI(filePathArr[filePathArr.length - 1])
              //设置操作类型为导出excle
              this.operate = 1;
              //下载文件
              this.downloadFile(res.data, name, 1)
              //关闭导出弹框
              this.closeExportListBox()
              this.loading.close();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        } else if (this.billsTypeSign == 7) { //生产执行
          exportData.fields = this.productProcessKeys.join(','); //需要导出的字段名
          exportData.isSubmit = 0; //是否仅导出已审核数据(需判断权限)
          //发送请求
          productApi.exportMesProcessList(exportData).then(res => {
            if (res.code == 200 && res.data) {
              let filePathArr = [];
              let name = '';
              filePathArr = res.data.split('//')
              name = decodeURI(filePathArr[filePathArr.length - 1])
              //设置操作类型为导出excle
              this.operate = 1;
              //下载文件
              this.downloadFile(res.data, name, 1)
              //关闭导出弹框
              this.closeExportListBox()
              this.loading.close();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        } else if (this.billsTypeSign == 8) { //发票
          exportData.fields = this.invoiceBillsKeys.join(','); //需要导出的字段名
          exportData.isSubmit = 0; //是否仅导出已审核数据(需判断权限)
          //发送请求
          costApi.exportCostInvoiceList(exportData).then(res => {
            if (res.code == 200 && res.data) {
              let filePathArr = [];
              let name = '';
              filePathArr = res.data.split('//')
              name = decodeURI(filePathArr[filePathArr.length - 1])
              //设置操作类型为导出excle
              this.operate = 1;
              //下载文件
              this.downloadFile(res.data, name, 1)
              //关闭导出弹框
              this.closeExportListBox()
              this.loading.close();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        } else if (this.billsTypeSign == 9) { //对账
          if(this.billsClassify==5){//当为销售对账单的时候
            exportData.fields = this.costSaleCheckKeys.join(','); //需要导出的字段名
          }
          else if(this.billsClassify==6){//当为采购对账单的时候
            exportData.fields = this.costPurchaseCheckKeys.join(','); //需要导出的字段名
          }
          exportData.isSubmit = 0; //是否仅导出已审核数据(需判断权限)
          exportData.billsClassify=this.billsClassify//单据相关类型
          //发送请求
          costApi.exportCostCheckList(exportData).then(res => {
            if (res.code == 200 && res.data) {
              let filePathArr = [];
              let name = '';
              filePathArr = res.data.split('//')
              name = decodeURI(filePathArr[filePathArr.length - 1])
              //设置操作类型为导出excle
              this.operate = 1;
              //下载文件
              this.downloadFile(res.data, name, 1)
              //关闭导出弹框
              this.closeExportListBox()
              this.loading.close();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },

      /* 初始化单据条件打印 */
      initPrintConditionData() {
        this.saleStartTime = ""; //订单开始时间
        this.saleEndTime = ""; //订单结束时间
        this.mesStartTime = ""; //计划单开始时间(计划单建单时间)
        this.mesEndTime = ""; //计划单结束时间(计划单建单时间)
        this.mesStartTime1 = ""; //计划单开始时间(计划单开工时间)
        this.mesEndTime1 = ""; //计划单结束时间(计划单开工时间)
        this.deliveryStartTime = ""; //交期开始时间
        this.deliveryEndTime = ""; //交期结束时间
        this.completeStartTime = ""; //完工开始时间
        this.completeEndTime = ""; //完工结束时间
        this.customerId = ""; //客户id
        this.productId = ""; //货品id

        //设置计划单结束时间为三天内
        let date = new Date();
        let nowtime = date.getTime(); //现在的时间
        let oneday = 1000 * 60 * 60 * 24; //一天的时间
        let mesStartTime = nowtime - (2 * oneday);
        this.mesStartTime = this.getDate(this.commonJsExtend.getDateTime(new Date(mesStartTime), 0));
        let mesEndTime = nowtime;
        this.mesEndTime = this.getDate(this.commonJsExtend.getDateTime(new Date(mesEndTime), 0));
      },

      getDate(strDate) {
        console.log(strDate);
        var date = eval('new Date(' + strDate.replace(/\d+(?=-[^-]+$)/,
          function(a) {
            return parseInt(a, 10) - 1;
          }).match(/\d+/g) + ')');
        return date;
      },

      /* 显示客户弹框 */
      getCustomer() {
        this.show_coustomerComponent = true;
        this.SHOW_CUSTOMERBOX(true); //客户弹框
      },

      /* 显示货品弹框 */
      getProductInfoBox() {
        this.show_productComponent = true;
        this.SHOW_PRODUCTBOX(true); //货品弹框
      },

      /* 获取客户组件返回的数据 */
      getCustomerData(data) {
        this.conditionCustomerId = data.customer_id;
        this.conditionCustomerName = data.customer_name;
      },

      /* 获取货品弹框返回的数据 */
      getProductData(data) {
        this.conditionProductId = data[0].id;
        this.conditionProductName = data[0].name;
      },

      /* 是否清空数据 */
      isClearCustomer() {
        if (this.conditionCustomerName == "") {
          this.conditionCustomerId = "";
        }
      },

      /* 是否清空货品数据 */
      isClearProduct() {
        if (this.conditionProductName == "") {
          this.conditionProductId = "";
        }
      },

      /* 提交条件打印生产计划单数据 */
      commitConditionList() {
        //判断订单时间周期
        if (!this.getDifferTime(this.saleStartTime, this.saleEndTime)) {
          return;
        }

        //判断计划单开始时间(计划单建单时间)
        if (!this.getDifferTime(this.mesStartTime, this.mesEndTime)) {
          return;
        }

        //计划单开始时间(计划单开工时间)
        if (!this.getDifferTime(this.mesStartTime1, this.mesEndTime1)) {
          return;
        }

        //判断交期时间周期
        if (!this.getDifferTime(this.deliveryStartTime, this.deliveryEndTime)) {
          return;
        }

        //判断完工时间周期
        if (!this.getDifferTime(this.completeStartTime, this.completeEndTime)) {
          return;
        }

        //定义后端接收参数
        let data = {
          saleStartTime: this.saleStartTime == "" ? "" : this.commonJsExtend.getDateTime(this.saleStartTime,
          0), //订单开始时间
          saleEndTime: this.saleEndTime == "" ? "" : this.commonJsExtend.getDateTime(this.saleEndTime, 0), //订单结束时间
          mesStartTime: this.mesStartTime == "" ? "" : this.commonJsExtend.getDateTime(this.mesStartTime,
          0), //计划单开始时间(计划单建单时间)
          mesEndTime: this.mesEndTime == "" ? "" : this.commonJsExtend.getDateTime(this.mesEndTime,
          0), //计划单结束时间(计划单建单时间)
          mesStartTime1: this.mesStartTime1 == "" ? "" : this.commonJsExtend.getDateTime(this.mesStartTime1,
          0), //计划单开始时间(计划单开工时间)
          mesEndTime1: this.mesEndTime1 == "" ? "" : this.commonJsExtend.getDateTime(this.mesEndTime1,
          0), //计划单结束时间(计划单开工时间)
          deliveryStartTime: this.deliveryStartTime == "" ? "" : this.commonJsExtend.getDateTime(this
            .deliveryStartTime, 0), //交期开始时间
          deliveryEndTime: this.deliveryEndTime == "" ? "" : this.commonJsExtend.getDateTime(this.deliveryEndTime,
          0), //交期结束时间
          completeStartTime: this.completeStartTime == "" ? "" : this.commonJsExtend.getDateTime(this
            .completeStartTime, 0), //完工开始时间
          completeEndTime: this.completeEndTime == "" ? "" : this.commonJsExtend.getDateTime(this.completeEndTime,
          0), //完工结束时间
          customerId: this.conditionCustomerId, //客户id
          productId: this.conditionProductId, //货品id
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#conditionBox", 4, '正在获取满足条件的计划单据,请稍候...');
        //发送请求
        productApi.findMesIdStrByCondition(data).then(res => {
          if (res.code == 200) {
            if (res.data == null) {
              this.$message({
                type: 'warning',
                message: "没有满足条件的单据,请确认!",
                duration: this.elDuration
              })
            } else {
              //打开打印界面
              this.openMesConditonPrintPage(res.data);
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
          //关闭loading框
          this.loading.close();
        })
      },

      /* 打开生产排程单打印界面 */
      openMesConditonPrintPage(mesIds) {
        //设置单据模块
        setCookie('billsTypeSign', this.billsTypeSign);
        //设置是否显示旧物料编码
        setCookie('showOldProductCode', this.companyparamlist.param345 == "0" ? true : false);
        //设置生产打印模块子分类
        setCookie('mesThName', 8); //生产排程单
        //设置请求id
        setCookie('billsId', mesIds);
        //获取生产计划排程单魔板相关信息
        let printData = {
          code: 'mes_schedule'
        }
        workflowApi.findOrderPrindMouldByCode(printData).then(res => {
          if (res.code == 200) {
            //设置打印模板id
            setCookie('printMouldId', res.data.id);
            //打开打印页面
            window.open(baseUrl + res.data.file_path);
          } else {
            this.$message({
              type: 'warning',
              message: "生产计划排程单打印模板获取失败,请联系管理员!",
              duration: this.elDuration
            })
          }
        })
      },

      /* 显示附件预览弹框 */
      showFilePreView(data) {
        //关闭高级打印弹框
        this.isShowAdvancedBox = false;
        //判断有无数据
        if (data.name != '' && data.file_path != '') {
          //获取预览路径
          this.$nextTick(() => {
            this.previewFilePath = data.name + "@@@" + data.file_path;
            //显示弹框
            this.SHOW_FILEPREVIEW(true);
          })
        }
      },

      /* 获取日期选择弹框数据*/
      getSelectData(date, filed) {
        // if (!!date) {
        //   //将选择后的日期格式进行转换,不转换存的是国际标准时间
        //   this. [filed] = this.commonJsExtend.getDateTime(date, 0);
        //   //强制刷新数据($set不能解决,暂用这种方式)
        //   this.$forceUpdate();
        // }
      },

      /* 关闭高级打印弹框 */
      closeExportBox() {
        this.isShowAdvancedBox = false;
      },

      /* 关闭导出列表弹框 */
      closeExportListBox() {
        this.isShowExportListBox = false;
      },

      /* 关闭条件选择弹框 */
      closeConditionListBox() {
        this.isShowConditionListBox = false;
      },

      // 获取时间差startTime:开始时间  endTime:结束时间
      getDifferTime(startTime, endTime) {
        if (startTime == "" && endTime == "") {
          return true;
        }
        //判断日期区间
        let today = new Date(); //今天日期
        today.setHours(0, 0, 0, 0);
        if (startTime == '' || endTime == '') { //起止时间其中一个为空
          this.$message({
            type: 'warning',
            message: '起止日期不能为空!',
            duration: this.elDuration
          })
          return false;
        } else if (endTime.getTime() - startTime.getTime() < 0) { //结束日期不能早于开始日期
          this.$message({
            type: 'warning',
            message: '结束日期不能早于开始日期!',
            duration: this.elDuration
          })
          return false;
        } else if (today.getTime() < startTime.getTime() || today.getTime() < endTime
          .getTime()) { //所选择的日期不能早于当前日期
          this.$message({
            type: 'warning',
            message: '所选择的日期不能早于当前日期!',
            duration: this.elDuration
          })
          return false;
        } else { //限制日期选择区间
          let duringTime = (endTime.getTime() - startTime.getTime()) / (24 * 60 * 60 * 1000)
          if (duringTime > 90) {
            this.$message({
              type: 'warning',
              message: '日期区间不能超过90天!',
              duration: this.elDuration
            })
            return false;
          }
        }
        return true;
      },
    },
    components: {
      filePreview,
      customerList,
      productList,
    }
  }
</script>

<style lang="less" scoped>
  // 头部选择单据类型样式
  .headSelBillsType {
    display: flex;
    align-items: center;
    padding: 0px 0 20px 0;

    // border: 1px solid black;
    .title {
      padding-left: 30px;
      // border: 1px solid black;
    }

    .content {
      // border: 1px solid black;
    }
  }

  /* 导出列表弹框样式 */
  .exportListBox {
    padding: 0 0 30px 0;
    // border: 1px solid black;
  }

  /deep/.el-dialog__body {
    padding-bottom: 6px !important;
  }

  .isUseHistory {
    margin: 10px;

    .historyBillsModel {
      text-align: center;
      color: red;
    }

    .isClick {
      cursor: pointer;
    }
  }

  .tip {
    padding: 10px 17px 5px;
  }

  .fontRed {
    color: red;
  }

  .fontWeight {
    font-weight: 600;
  }

  /* 条件打印单据框内容 */
  .conditionBox {

    //头部提示
    .conditon_title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      padding: 5px 0 10px 0;
      // border: 1px solid black;
    }

    // 单个条件
    .conditon_item {
      padding: 5px 0;
    }
  }
</style>
